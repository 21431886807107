<template>
  <v-container class="px-md-0" data-cy="NoFlightDiscoverPage">
    <v-row class="mx-auto discover-no-flight">
      <WelcomeCitiesCards
        :title="$t('welcome.exploreDestinationsTitle')"
        :items="cities"
        class="mt-10"
      />
      <WelcomeExperiencesCards
        v-for="city in citiesWithExperiences"
        :key="city.title"
        :title="
          $t(`discovery.${city.titleTemplate || 'top_rated'}`, {
            city: city.title,
          })
        "
        :items="city.items"
        :query="cityQuery(city)"
        class="mt-14"
        :per-page="
          $vuetify.breakpoint.lgAndUp ? Math.min(6, city.items.length) : null
        "
      />
    </v-row>
  </v-container>
</template>

<script>
import WelcomeCitiesCards from '@/modules/welcome/features/WelcomeHooksCta/WelcomeCitiesCards';
import WelcomeExperiencesCards from '@/modules/welcome/features/WelcomeHooksCta/WelcomeExperiencesCards';
import DiscoveryPageMixins from '@/modules/discovery/mixins/DiscoveryPageMixins';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'NoFlightDiscoverPage',
  mixins: [DiscoveryPageMixins, ConfigsMixins],
  components: { WelcomeExperiencesCards, WelcomeCitiesCards },

  computed: {
    cities() {
      return (
        this.noFlightCities ?? [
          {
            title: 'Paris',
            titleTemplate: 'best_of',
            preview: '/img/discover/paris.jpg',
            query: { city: 'Paris', lng: 2.35183, lat: 48.85658 },
          },
          {
            title: 'Hong Kong',
            titleTemplate: 'top_rated',
            preview: '/img/discover/hong_kong.jpg',
            query: {
              city: 'Hong Kong',
              lng: 114.15861,
              lat: 22.27833,
            },
          },
          {
            title: 'Los Angeles',
            titleTemplate: 'explore',
            preview: '/img/discover/los_angeles.jpg',
            query: {
              city: 'Los Angeles',
              lng: -118.2439,
              lat: 34.0544,
            },
          },
          {
            title: 'Sydney',
            titleTemplate: 'unique_experiences',
            preview: '/img/discover/sydney.jpg',
            query: { city: 'Sydney', lng: 151.21, lat: -33.868 },
          },
          {
            title: 'London',
            titleTemplate: 'best_of',
            preview: '/img/discover/london.jpg',
            query: {
              city: 'London',
              lng: -0.12764739999999997,
              lat: 51.507321899999994,
            },
          },
          {
            title: 'New York City',
            titleTemplate: 'city_awaits',
            preview: '/img/discover/new_york.jpg',
            query: { city: 'New York', lng: -73.9866, lat: 40.7306 },
          },
        ]
      );
    },

    citiesWithExperiences() {
      return this.cities
        .map((city) => ({
          ...this.noFlightCatalogGroups.find(
            (catalog) => catalog.city === city.title
          )?.data,
          ...city,
        }))
        .filter((cityCatalog) => cityCatalog.items?.length);
    },
  },

  mounted() {
    this.loadNoFlightCatalogGroupsGroups(this.cities);
  },

  methods: {
    cityQuery(city) {
      return { ...city.query, city: city.title };
    },
  },
};
</script>

<style scoped></style>
